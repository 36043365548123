import * as React from "react";
import { useState } from 'react';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import QRCode from "react-qr-code";
import { osName, isMobile, OsTypes } from "react-device-detect";
import Typography from "@mui/material/Typography";

export default function App() {
  let initialState = '';
  if (window.location.href.includes('closebrowser')) {
    initialState = 'closebrowser';
  } 
  const [closeRequest, setCloseRequest] = useState(initialState);

  React.useEffect(() => {
    console.log("start");
    if (closeRequest !== '') {
      return;
    }
    console.log("setting Timeout");
    setTimeout(() => {
      if (isMobile) {
        console.log("Handling mobile redirect");
        if (osName === OsTypes.Android) {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.coingrig";
        } else if(osName === OsTypes.IOS){
          window.location.href =
            "https://apps.apple.com/us/app/coingrig-crypto-btc-wallet/id1583464451";
        }
      }
    }, 1500);
  }, []);

  let QRCard = (
    <Card sx={{ maxWidth: 345, mx: "auto", mt: 10 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" src="/assets/logo.jpeg">
            G
          </Avatar>
        }
        title="Coingrig"
        subheader="Digital Wallet"
      />
      <Box
        sx={{
          width: 250,
          height: 250,
          mx: "auto",
          mt: 0.5,
          mb: 0.5
        }}
      >
        <QRCode value={window.location.href} size={250} />
      </Box>
      <CardContent>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          Open this link on your mobile or scan it from your mobile QR code
          reader.
        </Typography>
      </CardContent>
    </Card>
  );

  let CloseButton = (
    <Card sx={{ maxWidth: 345, mx: "auto", mt: 10 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" src="/assets/logo.jpeg">
            G
          </Avatar>
        }
        title="Coingrig"
        subheader="Digital Wallet"
      />
      <Box
        sx={{
          width: 250,
          height: 250,
          mx: "auto",
          mt: 0.5,
          mb: 0.5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <center>
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              var a = document.createElement('a');
              a.setAttribute("href", "coingrig://closebrowser");
              
              const dispatch = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
              });
              a.dispatchEvent(dispatch);            
            }}
          >
            Click this link to return to the Coingrig App and finish the process.
          </Link>
        </center>
      </Box>
      <CardContent>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          The browser was not able to automatically finish the process.
        </Typography>
      </CardContent>
    </Card>
  );

  if (closeRequest === '') {
    return QRCard;
  }
  return CloseButton;
}
